// Efeito do menu
$('.clique-menu').click(function(){
  $('.menu-responsivo').slideDown();
});

$('.fechar-menu').click(function(){
  $('.menu-responsivo').slideUp();
});



// Slide

$('.slider-desktop').slick({
  dots: true,
  infinite: true,
  speed: 700,
  arrows: true,
  prevArrow: $('.seta-direita'),
  nextArrow: $('.seta-esquerda'),
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.slider-mobile').slick({
  dots: false,
  infinite: true,
  speed: 700,
  arrows: true,
  prevArrow: $('.seta-direita'),
  nextArrow: $('.seta-esquerda'),
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.galeria').slick({
  dots: true,
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
  {
    breakpoint: 1024,
    settings: {
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    autoplay: true,
    dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
    slidesToShow: 3,
    slidesToScroll: 3,
     dots: true
    }
  },
  {
    breakpoint: 480,
    settings: {
    slidesToShow: 1,
    slidesToScroll: 1,
     dots: true
    }
  }
  // You can unslick at a given breakpoint now by adding:
  // settings: "unslick"
  // instead of a settings object
  ]
});



// Maps

// Maps

function initMap() {

var myLatLng = {lat: -5.8333464, lng: -35.214429};

var map = new google.maps.Map(document.getElementById('map'), {
  center: myLatLng,
  zoom: 18,
  scrollwheel: false
  });

var marker = new google.maps.Marker({
  position: myLatLng,
  icon: 'assets/img/pin_convictus.png',
  map: map,
  title: 'Softline'
  });

}

// accordion 
var acc = document.querySelectorAll(".accordion");

for (var i = 0; i < acc.length; i++) {
  acc[i].onclick = function(){
    this.classList.toggle("active");

    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }
}


$(function() { 
  var videos  = $(".video");

    videos.on("click", function(){
      var elm = $(this),
        conts   = elm.contents(),
        le      = conts.length,
        ifr     = null;

      for(var i = 0; i<le; i++){
        if(conts[i].nodeType == 8) ifr = conts[i].textContent;
      }

      elm.addClass("player").html(ifr);
      elm.off("click");
    });
});
